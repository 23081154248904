import React from "react";
import { Link } from "gatsby"
import Cookies from 'universal-cookie';


class CookieInfo extends React.Component{

    render (){ 

        const cookies = new Cookies();

            function okayCookie(){
                if (typeof document !== 'undefined') {
                    cookies.set('cookieOkayed', new Date().getTime(), { path: '/', expires: (new Date(new Date().getTime()+1200*24*60*60*1000)) });

                    document.getElementsByClassName("cookie--box")[0].classList.add("hidden")
                }
            }
            return(            
            <div>

            {typeof document !== 'undefined' && cookies.get('cookieOkayed') === undefined && (
                <div className="cookie--box">
                    <p>Dette website bruger cookies for at sikre at du får så god en oplevelse som muligt. Ved at bruge dette website accepterer du at anonyme oplysninger om brug behandles i overensstemmelse med vores <Link to="/privacy-policy">privatlivspolitik</Link>.</p>
                    <button onClick={okayCookie}>Okay</button>
                </div>
            )}
            </div>
        )
    }

      
}

export default CookieInfo


import 'core-js';
import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import CookieInfo from "./CookieInfo"
import Img from "gatsby-image/withIEPolyfill"
import Header from "./header"
import "./layout.css"
// import CustomCursor from './CustomCursor';
import SocialMedia from './SocialMedia';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'


class Layout extends React.Component {
  
    componentDidCatch() {
      
      document.getElementsByTagName("BODY")[0].classList.remove("noscroll")

      if (typeof NodeList !== `undefined`) {
        (function () {
          if ( typeof NodeList.prototype.forEach === "function" ) return false;
          NodeList.prototype.forEach = Array.prototype.forEach;
        })();
      }

    }

    componentDidMount() {
    }
    
    
    render() {
      library.add(fab)
      const props = this.props
      return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
            allDirectusSocialMedia(filter: {status: {eq: "published"}}) {
              edges {
                node {
                  social_channel
                  url
                  handle
                }
              }
            }
            allDirectusPageSetting {
              edges {
                node {
                  id
                  main_email
                  phone_number        
                  logo {
                    localFile {
                      childImageSharp {
                        fluid(quality: 30, maxWidth: 500) {
                          ...GatsbyImageSharpFluid_withWebp                      
                        }
                      }
                    }
                  }
                }
              }
            }
            allDirectusSponsor {
              edges {
                node {
                  name
                  link
                  id
                  logo {
                    localFile {
                      childImageSharp {
                        fluid(quality: 15, maxWidth: 200) {
                          ...GatsbyImageSharpFluid_withWebp                      
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            <Header siteTitle={data.site.siteMetadata.title}/>
            <div id="content" className={props.location.pathname !== "/" ? "" : "homepage"}>
              <main
                role="main"
                className="col-md-9 ml-sm-auto col-lg-10 px-4 mb-5"
              >
                <div className="d-flex">{props.children}</div>
              </main>
            </div>
            {props.location.pathname !== "/intro" && (
              props.children.key !== "/404.html" && (
              <footer id="colophon" className="site-footer">
                <div className="site-info">
                    {data.allDirectusPageSetting.edges.map(edge => (
                      <div className="footer--logo" key="footer--logo">
                        <Img
                          fluid={edge.node.logo.localFile.childImageSharp.fluid}
                          className="footer_logo"
                          key={edge.node.id}
                        />
                        <div className="footer--contact-social">
                          {data.allDirectusSocialMedia.edges.map((edge, index) => (
                            <SocialMedia data={edge.node} key={index}/>
                          ))}
                        </div>
                      </div>
                    ))}
                    {data.allDirectusPageSetting.edges.map(edge => (
                      <div className="footer--contact" key="footer--contact">
                        <h4 className="medium">Kontakt</h4>
                        <p>P: <a href={`tel:+45 ${edge.node.phone_number}`} className="footer--phone-number">{`+45 ${edge.node.phone_number}`} </a></p>
                        <p>E: <a href={`mailto:${edge.node.main_email}`} className="footer--email">{edge.node.main_email}</a></p>
                      </div>
                    ))}
                  <div className="footer--commercial">
                    {/* <h4 className="medium">Kommerciel partner</h4>
                    <div className="footer--commercial-inner">
                      <a href={data.directusCommercialPartner.web_address} className="footer--commercial-item" target="_blank" rel="noopener noreferrer">
                        <Img fluid={data.directusCommercialPartner.logo.localFile.childImageSharp.fluid} />
                      </a>
                      <div className="footer--commercial-text">
                        <p><b>{data.directusCommercialPartner.name}</b></p>
                        <p dangerouslySetInnerHTML={{__html:data.directusCommercialPartner.address}}></p>
                        <p><a href={`mailto:${data.directusCommercialPartner.email_address}`}>{data.directusCommercialPartner.email_address}</a></p>
                        <p><a href={`tel:+45 ${data.directusCommercialPartner.phone_number}`}>{`+45 ${data.directusCommercialPartner.phone_number}`}</a></p>
                      </div>
                    </div> */}
                  </div>
  
                  <div className="footer--partners">
                    <h4 className="medium">Partnere</h4>
                    <div className="footer--partners-inner">
                    {data.allDirectusSponsor.edges.map(edge => (
                      <a href={edge.node.link} className="footer--partners-item" key={edge.node.id} target="_blank" rel="noopener noreferrer">
                        <Img fluid={edge.node.logo.localFile.childImageSharp.fluid} objectFit="contain" />
                      </a>
                    ))}
                    </div>
                  </div>
                </div>
                <div className="footer--bottom-links">
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </div>
              </footer>
            ))}
            <CookieInfo />
            {/* <CustomCursor /> */}
          </>
        )}
      />
    )
  }
}
Layout.defaultProps = {
  location: {},
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const socialMedia = (props) => {
    return(
        <a href={props.data.url} target="_blank" rel="noopener noreferrer">
            <span style={{display: 'inline-block'}}>
                <FontAwesomeIcon icon={['fab', props.data.social_channel]} />
            </span>
        </a>
    ) 
}

export default socialMedia
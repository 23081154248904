import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'


class Header extends React.Component  {
  constructor(props){
    super(props);

    this.state = {
      expanded: false,
      opacity: 0
    }
  }


  componentDidMount() {
    window.addEventListener('scroll', function(){
      if(window.pageYOffset > 180){
        document.getElementsByTagName("HEADER")[0].classList.add("scrolled")
      } else {
        document.getElementsByTagName("HEADER")[0].classList.remove("scrolled")
      }
    })

    document.getElementsByClassName("menu-toggle")[0].addEventListener("click", (button) => {
      button.target.parentElement.getAttribute("aria-expanded")
      ? (document.getElementsByTagName("body")[0].classList.add("noscroll"))
      : (document.getElementsByTagName("body")[0].classList.remove("noscroll"))
    })

    this.delayIn()
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
  }

  delayIn() {
    const items = document.querySelectorAll("#primary-menu li")
    for (const [index, item] of items.entries()) {
      item.style.transitionDelay = 0.08 * index + 's'
    }
  }

  escFunction = (event) => {
    if(event.keyCode === 27) {
      this.setState({
        expanded: false,
        opacity: 0
      })
      document.getElementsByTagName("body")[0].classList.remove("noscroll")
    }
  }

  toggleMenu = () => {
    this.setState({
      expanded: !this.state.expanded,
      opacity: this.state.opacity === 0 ? 1 : 0
    })
    !this.state.expanded
    ? (document.getElementsByTagName("body")[0].classList.add("noscroll"))
    : (document.getElementsByTagName("body")[0].classList.remove("noscroll"))
  }


  render(){
    const { data, className } = this.props


  return (
  <header className={`site-header${className ? " " +className : ""}`} id="masthead">
    <div className="site-branding">
      <Link to="/" className="custom-logo-link">
        <Img fluid={data.directusPageSetting.logo.localFile.childImageSharp.fluid} className="custom-logo"/>
      </Link>
    </div>
    <nav className="main-navigation" id="site-navigation">
      <button className="menu-toggle" aria-controls="primary-menu" aria-expanded={this.state.expanded} aria-label="Menu button" onClick={this.toggleMenu}>
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="cross"></span>
      </button>
      <div className="menu-frontend-menu-container" aria-expanded={this.state.expanded} style={{
        opacity: this.state.opacity,
        transition: this.state.opacity === 1 ? 'opacity 0.2s ease 0.2s' : 'opacity 0.2s ease'
      }}>
        <BackgroundImage fluid={data.directusPageSetting.menu_background_image.localFile.childImageSharp.fluid} style={{
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundRepeat: 'no-repeat!important',
          backgroundSize: 'contain!important',
          backgroundPosition: 'right center!important',
        }}> 
          <ul id="primary-menu" className="menu" style={{
            background: 'linear-gradient(to right, #001217, #001217 63%, rgba(0, 18, 23, 0.56) 95%)',
          }}>
          {data.allDirectusMenu.edges.map(edge => (
            <li key={edge.node.id} className={"menu-item menu-item-type-post_type menu-item-object-page " + edge.node.id}>
              <Link to={edge.node.link_to ? "/" + edge.node.link_to : "/"} onClick={this.toggleMenu}>{edge.node.name}</Link>
            </li>
          ))}        
          </ul>
          {/* <div className="primary-menu--bg">
            <Img fluid={data.directusPageSetting.menu_background_image.localFile.childImageSharp.fluid} />
          </div> */}
        </BackgroundImage>
      </div>
    </nav>
  </header>

  )  
}

}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        directusPageSetting {
          logo {
            localFile {
              childImageSharp {
                fluid(quality: 25, maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp                
                }
              }
            }
          }
          name
          menu_background_image {
            localFile {
              childImageSharp {
                fluid(quality: 70, maxWidth: 700) {
                  ...GatsbyImageSharpFluid_withWebp                
                }
              }
            }
          }
        }
        allDirectusMenu(filter: {status: {eq: "published"}}, sort: {fields: sort, order: ASC}) {
          edges {
            node {
              name
              link_to
              id
            }
          }
        }
      }      
    `}
    render={data => <Header data={data} {...props} />}
  />
)


